
import LayeredParallax from "@/components/base/LayeredParallax"

export default {
    name: "LandshutArbeitgeberFooter",
    components: { LayeredParallax },
    data: () => ({
        parallaxLayers: [
            require("@/assets/images/region/deggendorf/parallax/l6.svg"),
            require("@/assets/images/region/deggendorf/parallax/l5.svg"),
            require("@/assets/images/region/deggendorf/parallax/l4.svg"),
            require("@/assets/images/region/deggendorf/parallax/l3.svg"),
            require("@/assets/images/region/deggendorf/parallax/l2.svg"),
            require("@/assets/images/region/deggendorf/parallax/l1.svg"),
        ],
    }),
    computed: {
        parallaxLayerTransforms() {
            return this.$breakpoint.mdAndDown
                ? [0, 0, 0, 0, 0, 0]
                : [0.98, 1.06, 1.03, 1, 0.97, 0.95]
        },
        parallaxHeight() {
            if (this.$breakpoint.mdAndDown) return 550
            if (this.$breakpoint.lgAndDown) return 780
            if (this.$breakpoint.xlAndDown) return 800
            return 1200
        },
    },
}
